@mixin normalTransition {
    transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.175);
    // transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

* {
    &, &::before, &::after {
        padding: 0;
        margin: 0;
        scroll-behavior: smooth;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    line-height: 1.6;
}

body {
    background-color: color('background');
    color: color('font');
    font-family: 'Arimo', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
}

.wrapper {
    position: relative;
}

.container {
    padding: 1.5rem;
    width: 100%;
    margin: 0 auto;
}

@include breakpoint-up('xl') {
    .container {
        max-width: 1440px;
    }
}

button {
    color: color('font');
    font-family: inherit;
    font-size: .9rem;
    @include normalTransition;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: 500;
}

input,
textarea {
    border: .075rem solid color('border');
    outline: 0;
    background-color: transparent;
    color: color('headline');
    font-family: inherit;
    font-size: .9rem;
    @include normalTransition;

    &:hover,
    &:focus {
        border-color: color('primary');
    }
}

.event-none {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.loading {
    &-container {
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 50;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: color('background');
    }

    &-icon {
        color: color('primary');
        font-size: 3rem;
        animation: loadingIcon 1s ease-in-out infinite;
    }
}

@keyframes loadingIcon {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}