h1,
h2,
h3,
h4,
h5,
h6 {
    color: color('headline');
    font-weight: 700;
    line-height: 1.2;
    margin: .75rem 0;
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    color: #fff;
    font-size: 1.5rem;
}

h4 {
    font-size: 1.35rem;
}

a,
.link {
    text-decoration: none;
    color: darken(color('primary'), 5%);
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}