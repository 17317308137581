
 .container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  h2 {
    font-size: 26px;
    margin: 10%;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }
  
  .responsive-table {
    li {
      border-radius: 3px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .table-header {
      background-color: #95A5A6;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .table-row {
      background-color: #ffffff;
      box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    }
    .col-1 {
      flex-basis: 25%;
    }
    .col-2 {
      flex-basis: 15%;
    }
    .col-3 {
      flex-basis: 20%;
    }
    .col-4 {
      flex-basis: 15%;
    }
    .col-5 {
        flex-basis: 15%;
    }
    .col-6 {
        flex-basis: 10%;
    }
    
    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
      .table-row{
        display: none;
      }
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }

  $blue: #ec0f6b;

  
  header {
    display: flex;
    padding: 0 15px;
    max-width: 800px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    img {
      width: 30px;
    }
  }
  
  li {
    padding: 6px;
    a {
      text-decoration: none;
      color: black;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  
  
  .button {
    text-transform: uppercase;
    background-color: $blue;
    border: 2px solid white;
    border-radius: 30px;
    text-decoration: none;
    padding: 10px 28px;
    color: white;
    margin-top: 10px;
    display: inline-block;
    &:hover {
      background-color: white;
      color: $blue;
      border: 2px solid $blue;
    }
  }
  
  .card-container {
    max-width: 800px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .card {
    height: 300px;
    width: 230px;
    border: 1px solid darkgray;
    border-radius: 3px;
    margin: 10px;
  }
  
  // MODAL STARTS HERE //
  
  .bg-modal {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: none;
    justify-content: center;
    align-items: center;
  }
  
  .modal-contents {
    height: 300px;
    width: 500px;
    background-color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    border-radius: 4px;
  }
  
  input {
    margin: 15px auto;
    display: block;
    width: 100%;
    padding: 8px;
    border: 1px solid gray;
  }
  
  .close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    color: #333;
    transform: rotate(45deg);
    cursor: pointer;
    &:hover {
      color: #666;
    }
  }
  
